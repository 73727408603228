@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400&display=swap);
:root {
  --primary-color: #005FAF;
  --white-color: #FFF;
}

/**
 * Global Font Styles
 */

* {
  font-family: 'Poppins', sans-serif;
}

.form-title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #363B3E;
}

.form-subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #A5B1C0;
  margin-bottom: 20px;
}

/**
 * Global Background Stylse
 */

.body-container {
   min-height: calc(100vh - 42px);
  background: url(/static/media/banner-bg.e931ec40.png) no-repeat left bottom/100%;
  background-size: 200%;
  background-position-y: 85%;
}

/**
 * Header Styles
 */

.header {
  background-color: #005FAF;
  background-color: var(--primary-color);
  color: #FFF;
  color: var(--white-color);
}

.header > * {
  flex-basis: 0;
}

.language-buttons {
  font-size: 10.5px;
}

.language-buttons-selected {
  background-color: #FFF;
  background-color: var(--white-color);
  color: #005FAF;
  color: var(--primary-color);
}

/**
 * LandingPage Styles
 */

 .login-form {
  min-height: calc(100vh - 42px);
  margin: 0 20px;
  position: relative;
}

/* 
* Global button css
*/

.md-button{
  padding: 7px 15px;
  min-width: 180px;
    height: 54px;
    background: #222222;
    border-radius: 6px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.login-form .input-group .form-control::-webkit-input-placeholder{
  opacity: 0.2;
}

.login-form .input-group .form-control::placeholder{
  opacity: 0.2;
}
.login-form .input-group .form-control,
.delivery-detail .form-group .form-control{
  border: 1px solid #A5B1C0;
  border-radius: 8px;
  padding: 17px 20px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #444444;
  text-align: center;
}
.mb-20{
  margin-bottom: 20px;
}
.mb-10{
  margin-bottom: 10px;
}
.terms-condition-link{
  font-size: 12px;
}
.login-footer-btn{
  position: absolute;
    bottom: 13px;
}
.appointment-wrapper{
  padding: 36px 20px;
}
.error-msg{
  font-size: 14px;
}

.appointment-detail-h{
  letter-spacing: 0.01em;
  margin: 20px 0 11px;
}
.appointment-wrapper-box{
  flex-direction: column;
  background: #FFFFFF;
  border: 0.5px solid #A5B1C0;
  border-radius: 12px;
  padding: 1.5rem;
}
.appointment-wrapper-box ul{
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}
.confirmation-trailer-detail ul li label{
  font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #787878;
    margin-bottom: 1px;
}
.confirmation-trailer-detail ul li p{
  font-weight: 500;
font-size: 15px;
line-height: 22px;
color: #363B3E;
margin-bottom: 0;
}
.confirmation-trailer-detail ul li {
  margin-bottom: 20px;
}
.confirmation-trailer-detail ul li:last-child{
  margin-bottom: 5px;
}
.navigation-buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  position: absolute;
  bottom: 15px;
  left: 0;
}
.delivery-detail{
  padding: 36px 20px;
}
.check-box-wrapper .form-check{
  padding: 0;
  margin: 0;
}
.check-box-wrapper .form-check-input:checked[type=radio],
.check-box-wrapper .form-check .form-check-input{
  display: none;
}
.check-box-wrapper .form-check-input:checked + label{
  background-color: #222222;
  color: #fff;
}
.check-box-wrapper .form-check .form-check-label{
  padding: 10px;
    border: 1px solid #A5B1C0;
    border-radius: 7px;
    margin: 0 12px 12px 0;
}

.check-box-right-wrapper .form-check-input:checked[type=radio],
.check-box-right-wrapper .form-check .form-check-input{
  display: none;
}
.check-box-right-wrapper .form-check-input:checked + label::before{
    content: '\2713';
    border: 1px solid #000;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: inline-flex;
    background-color: #000;
    color: #fff;
    padding: 0;
    font-size: 11px;
    justify-content: center;
    left: 5px;
    position: absolute;
    top: 15px;
}
.check-box-right-wrapper .form-check .form-check-label{
    padding: 10px;
    border: 1px solid #A5B1C0;
    border-radius: 7px;
    margin: 0 12px 12px 0;
    padding-left: 25px;
    position: relative;
    line-height: 1.5;
    margin-bottom: auto;
}
.check-box-right-wrapper .form-check .form-check-label::before{
  content: '';
    border: 1px solid #000;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: inline-flex;
    padding: 0;
    font-size: 11px;
    justify-content: center;
    left: 5px;
    position: absolute;
    top: 15px;
}
.check-box-right-wrapper .form-check{
  padding-left: 0
}

.check-box-right-wrapper label{
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #363B3E;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #363B3E;
  margin-bottom: 9px;
}

.ReactModal__Overlay{
  background: rgb(255 255 255 / 88%);
}

.ReactModal__Content .modal{
  display: block;
  padding: 20px;
}

.ReactModal__Content{
  width: 95%;
  border-radius: 15px !important;
  min-height: 200px;
  border-color: 1px solid rgb(229 229 229) !important;
}

.ReactModal__Content[aria-label="Error-Modal"]::after,
.ReactModal__Content[aria-label="Term-Condition"]::after,
.ReactModal__Content[aria-label="confirm-mobile-number"]::after, 
.ReactModal__Content[aria-label="success-modal"]::after,
.ReactModal__Content[aria-label="term_condition-modal"]::after
{
    content: '';
    height: 15px;
    background: #c60909;
    height: 24px !important;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
}
.ReactModal__Content[aria-label="term_condition-modal"] .body-container{
      height: calc(100vh - 42px);
}
.ReactModal__Content[aria-label="Term-Condition"]{
  min-height: 80%;
}

/* .ReactModal__Content[aria-label="Term-Condition"] ol li{
  line-height: 22px;
    font-size: 14px;
    margin-bottom: 8px;
} */

.ReactModal__Content[aria-label="Term-Condition"]::after,
.ReactModal__Content[aria-label="confirm-mobile-number"]::after, .ReactModal__Content[aria-label="success-modal"]::after,
.ReactModal__Content[aria-label="term_condition-modal"]::after{
  background: #005FAF;
}

.ReactModal__Content[aria-label="term_condition-modal"]{
  max-height: 90vh;;
}

.ReactModal__Content[aria-label="success-modal"]{
  min-height: 270px;
}

.ReactModal__Content[aria-label="confirm-mobile-number"]{
  min-height: 30%;
}
.ReactModal__Content[aria-label="Error-Modal"] .modal{
  display: flex;
  align-items: center;
  justify-content: center;
}
.cancle-btn,.cancle-btn:hover{
  border: 1px solid #222222;
}
.ReactModal__Content .md-button{
  min-width: 90px;
  padding: 0;
  height: 45px;
}
.terms-condition-box{
  padding:46px 16px 24px;
}
.terms-condition-box h4{
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  padding-left: 15px;
  margin-bottom: 36px;
}
.terms-condition-box ul,.terms-condition-box ol{
  padding-left: 30px;
}
.terms-condition-box ul li,.terms-condition-box ol li {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #363B3E;
    margin-bottom: 25px;
    padding-left: 5px;
}
.terms-condition-box form{
      padding-left: 45px;
}
.terms-condition-box form input{
  margin-right: 5px
}
.language-section{
      height: calc(100vh - 42px);
  }

.ReactModal__Content[aria-label="success-modal"] .content{
  margin: 15px 0;
}

.ReactModal__Content[aria-label="success-modal"] .content h3{
  color: #77C190;
  font-weight: 600;
  font-size: 20px;
}

.ReactModal__Content[aria-label="success-modal"] .content img{
    margin-bottom: 15px;
    width: 27px;
}

.ReactModal__Content[aria-label="success-modal"] .content p{
  color: #787878;
  font-weight: 400;
  font-size: 12px;
}

.ReactModal__Content[aria-label="success-modal"] h4{
  font-size: 16px;
  margin-bottom: 20px;
}

.page-loading{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fffffff2;
  text-align: center;
  font-weight: 500;
}

.page-loading img{
    width: 50px;
    margin-bottom: 13px;
}
